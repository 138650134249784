<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-remove</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Dispensationen</v-toolbar-title>
      <v-spacer />
      <SearchField hint="Name oder Klasse" v-model="search"></SearchField>
      <template v-slot:extension>
        <TermTabs align-with-title v-model="term" />
        <v-btn
          :to="{ name: 'DispensationAdd' }"
          absolute
          bottom
          right
          color="success"
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="itemsFiltered"
        :sort-by="'startDate'"
        :item-class="() => 'clickable'"
        @click:row="
          (item) =>
            $router.push({
              name: 'DispensationEdit',
              params: {
                id: item.id,
              },
            })
        "
      >
        <template v-slot:item.student="{ item }">
          <PersonChip
            @click.stop
            :value="item.student"
            :to="{ name: 'Person', params: { id: item.student.id } }"
          />
        </template>
        <template v-slot:item.start="{ item }">
          <DateValue :value="item.startDate" />,
          <span v-if="item.startTime"> {{ item.startTime }}</span
          ><span v-else>morgens</span>
        </template>
        <template v-slot:item.end="{ item }">
          <DateValue :value="item.endDate" />,
          <span v-if="item.endTime"> {{ item.endTime }}</span
          ><span v-else>abends</span>
        </template>
        <template v-slot:item.physicalEducation="{ item }">
          <v-simple-checkbox :value="item.physicalEducation" readonly />
        </template>
      </v-data-table>
    </v-card>
    <router-view :term="term"></router-view>
  </v-container>
</template>
<script>
import { comparePeople, searchPerson } from "common/utils/people.js";
import DateValue from "common/components/DateValue.vue";
import PersonChip from "common/components/PersonChip.vue";
import SearchField from "@/components/SearchField";
import TermTabs from "@/components/TermTabs";

export default {
  name: "Dispensations",
  components: {
    DateValue,
    PersonChip,
    SearchField,
    TermTabs,
  },
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Schüler:in", value: "student", sort: comparePeople },
        { text: "von", value: "start" },
        { text: "bis", value: "end" },
        { text: "Grund", value: "description" },
        { text: "Sport", value: "physicalEducation" },
      ],
      items: [],
      term: null,
    };
  },
  computed: {
    itemsFiltered() {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter((item) =>
        searchPerson(item.student, this.search)
      );
    },
  },
  watch: {
    term() {
      this.fetchData();
    },
  },

  methods: {
    async fetchData() {
      if (!this.term) return;
      this.loading = true;
      const data = await this.apiList({
        resource: "absence/dispensation",
        query: `startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      for (const item of data) {
        item.start = item.startDate + item.startTime;
        item.end = item.endDate + item.endTime;
      }
      this.items = data;
      this.loading = false;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.update) {
      await this.fetchData();
    }
    next();
  },
};
</script>
